@import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600;700&display=fallback');

@import 'tailwindcss/base';
@import 'tailwindcss/components';

/* Additional styles */
@import '/src/css/additional-styles/utility-patterns.css';
@import '/src/css/additional-styles/range-slider.css';
@import '/src/css/additional-styles/toggle-switch.css';
@import '/src/css/additional-styles/flatpickr.css';
@import '/src/css/additional-styles/theme.css';
@import 'tailwindcss/utilities';

.eLstvw {
    background-color: #EEEEEE !important;
}
